import React, { useState } from 'react'
import NavToggle from './NavToggle'
import NavMenu from './NavMenu'

import { motion, AnimatePresence } from 'framer-motion'
import { defaultTransition } from '../utilities/animationHelpers'

const variants = {
  show: {
    opacity: 1,
  },
  hide: {
    opacity: 0,
  },
  exit: {
    opacity: 0,
  },
}

const Layout = ({ children, path }) => {
  const [navOpen, setNavOpen] = useState(false)

  const getPage = () => {
    let match = path.match(/page-\d+/)
    if (match) return match[0].replace('-', ' ')
  }

  const getBookInfo = () => {
    let match = path.match(/\/(.*?)\//)
    if (match)
      return {
        title: match[1].replace(/-/g, ' '),
        url: match[0],
      }
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: defaultTransition }}
      className='bg-blue-300 mx-auto min-h-screen md:w-11/12 relative'
    >
      <div>
        <AnimatePresence>
          {path !== '/' && (
            <motion.div
              variants={variants}
              initial='hide'
              animate='show'
              exit='exit'
            >
              <div>
                <NavToggle
                  setNavOpen={setNavOpen}
                  navOpen={navOpen}
                  bookInfo={getBookInfo()}
                />
                <NavMenu active={navOpen} />
              </div>
              <div className='absolute right-0 lg:mr-16 md:mr-8 mr-6 mt-8 md:text-3xl text-2xl z-20 bg-blue-100 text-blue-400 rounded-lg shadow px-6'>
                {getPage()}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      {children}
    </motion.div>
  )
}

export default Layout
