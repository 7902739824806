export const defaultTransition = {
  type: 'spring',
  damping: 80,
  mass: 0.8,
}

export const defaultPageTransition = {
  exit: {
    length: 0.3
  },
  entry: {
    delay: 0.3
  }
}