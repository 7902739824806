import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { defaultTransition, defaultPageTransition } from '../utilities/animationHelpers'

import TransitionLink from 'gatsby-plugin-transition-link'

const fadeVariants = {
  hidden: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
}

const overlayVariants = {
  hidden: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
}

const closeVariants = {
  hidden: custom => ({
    opacity: 0,
    x: custom ? 25 : -25,
    y: custom ? -45 : -25,
    transition: defaultTransition,
  }),
  enter: custom => ({
    opacity: 1,
    x: 0,
    y: custom ? -20 : 0,
    transition: defaultTransition,
  }),
  exit: {
    opacity: 0,
  },
}

const barVariants = {
  hidden: custom => ({
    opacity: 0,
    x: -1 * (20 + custom * 20),
    transition: defaultTransition,
  }),
  exit: custom => ({
    opacity: 0,
    y: 10 + custom * 2,
    transition: defaultTransition,
  }),
  show: {
    x: 0,
    opacity: 1,
    transition: defaultTransition,
  },
}

const navItemVariants = {
  show: (custom) => ({
    opacity: 1,
    y: 0,
    transition: {
      ...defaultTransition,
      delay: 0.1 * custom,
    },
  }),
  hidden: {
    opacity: 0,
    y: 30
  }
}

const NavToggle = ({ setNavOpen, navOpen, bookInfo }) => {
  const generateBars = () => {
    let bars = []

    for (let i = 0; i < 3; i++) {
      bars.push({
        className: 'w-10 rounded h-1 bg-blue-400',
        style: {marginBottom: '2px', marginTop: '2px'},
        variants: barVariants,
      })
    }

    return bars
  }

  const generateNavItems = () => [
    {
      label: 'Home',
      url: '/'
    },
    {
      label: `Index of "${bookInfo.title}"`,
      url: bookInfo.url
    },
  ]

  return (
    <div className={`absolute left-0 lg:pl-16 md:pl-8 pl-6 pt-8 z-30`}>
      <div className='z-40 absolute'>
        <AnimatePresence exitBeforeEnter>
          {!navOpen ? (
            <motion.div
              onClick={() => setNavOpen(!navOpen)}
              variants={fadeVariants}
              initial='hidden'
              animate='enter'
              exit='exit'
              key='ham'
              className='p-4 -ml-4 -mt-4 cursor-pointer bg-blue-100 shadow rounded flex flex-wrap items-center'
            >
              {generateBars().map((bar, i) => (
                <motion.div
                  {...bar}
                  key={i}
                  initial='hidden'
                  animate={!navOpen ? 'show' : 'exit'}
                  custom={i}
                />
              ))}
            </motion.div>
          ) : (
            <motion.div
              variants={fadeVariants}
              initial='hidden'
              animate='enter'
              exit='exit'
              key='close'
            >
              <div onClick={() => setNavOpen(!navOpen)} className='inline-block p-4 -ml-4 -mt-4 cursor-pointer'>
                <motion.div
                  key='x-left'
                  variants={closeVariants}
                  initial='hidden'
                  animate='enter'
                  exit='exit'
                  className='relative w-10 rounded h-1 bg-gray-100 mb-1 mt-4'
                  style={{ rotate: '45deg' }}
                  custom={0}
                />
                <motion.div
                  key='x-right'
                  variants={closeVariants}
                  initial='hidden'
                  animate='enter'
                  exit='exit'
                  className='relative w-10 rounded h-1 bg-gray-100 mb-1 mt-4'
                  style={{ rotate: '-45deg' }}
                  custom={1}
                />
              </div>
              <motion.ul className='md:text-5xl text-4xl text-white underline whitespace-no-wrap'>
                {generateNavItems().map((item, i) => (
                  <motion.li
                    key={i}
                    custom={i}
                    variants={navItemVariants}
                    initial='hidden'
                    animate='show'
                    className='mt-4'
                  >
                    <TransitionLink
                      className="capitalize"
                      onClick={() => setNavOpen(!navOpen)}
                      {...defaultPageTransition}
                      to={item.url}
                    >
                      {item.label}
                    </TransitionLink>
                  </motion.li>
                ))}
              </motion.ul>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <AnimatePresence>
        {navOpen && (
          <motion.div
            variants={overlayVariants}
            initial='hidden'
            animate='enter'
            exit='exit'
            className='fixed min-h-screen md:w-11/12 w-screen top-0 left-0 bg-blue-900 overflow-hidden z-30'
            style={{
              left: '50%',
              transform: 'translateX(-50%)'
            }}
          ></motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default NavToggle
